import React, { Component } from 'react'
import './InstallPrompt.css'

export default class InstallPrompt extends Component {
  handleClick = e => {
    e.preventDefault()

    const { onShow } = this.props

    onShow()
  }

  render() {
    const { app } = this.props
    const name = app.name || 'Untitled'
    const icon = app.icon || 'default-app-icon.png'
    const iconURL = `https://proton-resources-production.imgix.net/${icon}?fit=crop&w=64&h=64&`

    return (
      <div className="install-prompt">
        <div className="install-prompt-header">
          <img
            alt={name}
            src={iconURL}
            className="install-prompt-icon"
            width={32}
            height={32}
          />
          <h4 className="install-prompt-title">{name}</h4>
        </div>
        <span className="install-prompt-button" onClick={this.handleClick}>
          Add to Home Screen
        </span>
      </div>
    )
  }
}
