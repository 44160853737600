import React, { Component } from 'react'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'
import qs from 'qs'

import { getAuthToken } from '../../utils/libraries'

export default class Frame extends Component {
  getScale = () => {
    const { size } = this.props

    const { height } = size
    const margin = 30
    const windowHeight = Math.max(window.innerHeight, 400)
    const yPad = this.hasNotch() ? 20 : 90

    return (windowHeight - 2 * margin) / (height + 2 * yPad)
  }

  forceRender = () => {
    this.forceUpdate()
  }

  getBaseURL = () => {
    return `/preview`
  }

  hasNotch = () => {
    const { size } = this.props
    const { offset_top: offsetTop, os } = size

    return os === 'ios' && offsetTop > 20
  }

  getURL = () => {
    const { appId, size, previewType } = this.props

    const sessionToken = getAuthToken()

    const PREVIEW_BANNER_HEIGHT = 64

    const {
      width,
      height,
      os,
      offset_top: offsetTop,
      offset_bottom: offsetBottom,
    } = size
    const baseURL = this.getBaseURL()

    const params = {
      os,
      offset_bottom: offsetBottom,
      offset_top: offsetTop,
      offsetTop: PREVIEW_BANNER_HEIGHT,
      previewType,
      w: width,
      h: height,
      sessionToken,
    }

    const src = `${baseURL}/${appId}?${qs.stringify(params)}`

    return src
  }

  render() {
    const { size } = this.props

    const { width, height } = size
    const iphonex = this.hasNotch()
    const src = this.getURL()
    const scale = this.getScale()

    const styles = {
      transform: `scale(${scale})`,
      width: width + 40,
      height: height + (iphonex ? 40 : 180),
    }

    const innerStyles = {
      width: Math.ceil(scale * styles.width),
      height: Math.ceil(scale * styles.height),
    }

    return (
      <div className="preview-frame-wrapper">
        <DocumentEvents target={window} onResize={this.forceRender} />
        <div className="preview-frame-inner-wrapper" style={innerStyles}>
          <div
            className={classNames('preview-frame-border', { iphonex })}
            style={styles}
          >
            <div className="preview-frame-clip">
              {[
                <iframe
                  title="Frame"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  key={src}
                  src={src}
                  width={width}
                  height={height}
                  className="preview-frame"
                  allow="camera *; microphone *"
                />,
              ]}
            </div>
            <div className="preview-frame-cover" />
          </div>
        </div>
      </div>
    )
  }
}
