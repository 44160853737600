import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

import { initTracing } from '@protonapp/proton-runner'

import Renderer from './Renderer'
import ScrollStopper from './ScrollStopper'
import Share from './Share'
import './App.css'

// This higher-order component correctly formats Sentry transaction names for routes.
const SentryRoute = Sentry.withSentryRouting(Route)

const routerHistory = createBrowserHistory()

initTracing(process.env.REACT_APP_SENTRY_DSN, {
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(routerHistory),
})

const isRunningStandalone = () => {
  return (
    window.navigator.standalone === true ||
    window.matchMedia('(display-mode: standalone)').matches
  )
}

class App extends Component {
  componentDidMount() {
    if (isRunningStandalone()) {
      document.body.classList.add('standalone')
    }
  }

  componentWillUnmount() {
    if (isRunningStandalone()) {
      document.body.classList.remove('standalone')
    }
  }

  render() {
    return (
      <Router history={routerHistory}>
        <div className="app">
          <ScrollStopper />
          <Switch>
            <SentryRoute exact path="/" component={Share} />
            <SentryRoute path="/preview/:appId" component={Renderer} />
            <SentryRoute path="/:appId" component={Share} />
          </Switch>
          <div className="app-border" />
        </div>
      </Router>
    )
  }
}

export default App
