import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'
import axios from 'axios'
import Favicon from 'react-favicon'

import * as Sentry from '@sentry/react'
import { getAppIdUrl, getAppUrl, getAppIconUrl } from '../../utils/io'
import Loading from '../Shared/Loading'
import Renderer from '../Renderer'
import Frame from './Frame'
import { WEB, DEFAULT_SIZE } from './SizeMenu'
import AppDetails from './AppDetails'
import Attribution from './Attribution'
import Stripe from './Stripe'
import Spacer from './Spacer'
import NotFound from './NotFound'
import './Share.css'
import './WrappedSelect.css'
import AppLoadError from '../../utils/errors'

const PREVIEW_BANNER_HEIGHT = 64

const hasWebSettings = app => Boolean(app.magicLayout && app.webSettings)

const isValidPreviewType = previewType =>
  ['mobile', 'web'].includes(previewType)

const getPreviewType = app => {
  const { webSettings, primaryPlatform } = app

  if (!hasWebSettings(app)) {
    // Legacy apps
    return primaryPlatform
  }

  const settingsPreviewType = webSettings?.previewType
  // Default to web when invalid/unset
  const previewType = isValidPreviewType(settingsPreviewType)
    ? settingsPreviewType
    : 'web'

  return previewType
}

const getShowAdaloAttribution = app => {
  const { showBranding, primaryPlatform } = app

  const showAttributionOnMobile = showBranding ?? true
  const showAttributionWebSetting =
    app?.webSettings?.showAdaloAttribution ?? false

  // Mobile apps keep using legacy 'showBranding' property
  const showAdaloAttribution =
    primaryPlatform === 'mobile'
      ? showAttributionOnMobile
      : showAttributionWebSetting

  // Attribution is shown in AppFooter if cloning is enabled
  const cloningEnabled = app?.visibility

  return showAdaloAttribution && !cloningEnabled
}

const Preview = () => {
  const [app, setApp] = useState(null)
  const [error, setError] = useState(false)

  const { appId } = useParams()
  const { width: windowWidth } = useWindowSize()

  const requestApp = async () => {
    let url

    if (appId) {
      url = getAppUrl(appId)
    } else {
      const host = window.location.host.split(':')[0]
      const query = `host=${encodeURIComponent(host)}`
      const appIdUrl = getAppIdUrl(query)
      const { data } = await axios.get(appIdUrl)
      url = getAppUrl(data.id)
    }

    try {
      const { data: app } = await axios.get(url)
      setApp(app)
      document.title = app.name
    } catch (error) {
      setError(true)
      Sentry.captureException(
        new AppLoadError(`Failed to share app: ${url}`, error)
      )
      console.error(`ERROR: ${error}`)
    }
  }

  useEffect(() => {
    requestApp()
  }, [])

  if (error) {
    return <NotFound />
  }

  if (!app) {
    return <Loading expanded />
  }

  const showAdaloAttribution = getShowAdaloAttribution(app)

  let size = null
  const previewType = getPreviewType(app)

  if (previewType === 'web') {
    size = size || WEB
  }

  if (size === WEB) {
    return (
      <div>
        <Favicon url={`${getAppIconUrl(app.id)}?size=32&radius=7`} />
        <div className="web-preview-header">
          <Stripe className="top-stripe" branding={app.branding} />
          <div className="web-preview-header-logo" />
          <Spacer />
        </div>
        <div className="web-renderer-wrapper">
          <Renderer
            appId={app.id}
            offsetTop={PREVIEW_BANNER_HEIGHT}
            previewType={previewType}
          />
        </div>
        {showAdaloAttribution && <Attribution />}
      </div>
    )
  }

  if (windowWidth < 890) {
    return <Renderer analytics appId={app.id} previewType={previewType} />
  }

  size = size || DEFAULT_SIZE

  return (
    <React.Fragment>
      <Favicon url={`${getAppIconUrl(app.id)}?size=32&radius=7`} />
      <Stripe branding={app.branding} className="top-stripe" />
      <div className="preview">
        <AppDetails app={app} />
        <Frame appId={app.id} size={size} previewType={previewType} />
      </div>
      {showAdaloAttribution && <Attribution />}
    </React.Fragment>
  )
}

export default Preview
