import React, { Component, Fragment } from 'react'
import qs from 'qs'
import axios from 'axios'
import SafeAreaInsets from 'safe-area-insets'
import StatusBar from '@protonapp/react-status-bar'
import { Helmet } from 'react-helmet-async'
import * as Sentry from '@sentry/react'

import {
  DATABASE_URL,
  DATABASE_API_URL,
  ASSETS_URL,
  FILE_UPLOADS_URL,
  IMAGE_UPLOADS_URL,
  NOTIFICATIONS_URL,
} from '../config'

import { getAppIdUrl, getAppUrl, getAppIconUrl } from '../utils/io'
import { getLibraries } from '../utils/libraries'
import InstallPrompt from './InstallPrompt'
import LibraryLoader from './LibraryLoader'
import IOSInstallPrompt from './IOSInstallPrompt'
import Runner from './RunnerWrapper'
import FeatureFlagIdentitySync from '../flags/FeatureFlagIdentitySync'
import AppLoadError from '../utils/errors'

import '@protonapp/react-status-bar/styles.css'
import './Renderer.css'

const isMobile = window === window.top

// Current implementation for android install prompt is broken, so we disable it until we can fix it.
// As it is, the installPrompt state attribute should be the 'beforeinstallprompt' event
// the InstallPrompt component should get a handler that calls the `.prmopt()` method on that event
const ANDROID_INSTAL_PROMPT_ENABLED = false

export default class Renderer extends Component {
  state = {
    app: null,
    authenticationError: false,
    currentComponentId: null,
    installPrompt: false,
    librariesReady: false,
    error: false,
    appId: null,
  }

  getAppId = () => {
    const {
      match: { params: { appId: routeAppId } = {} } = {},
      appId,
    } = this.props

    return appId || routeAppId
  }

  requestApp = async () => {
    const appId = this.getAppId()

    let url

    if (appId) {
      url = getAppUrl(appId)
    } else {
      try {
        const host = window.location.host.split(':')[0]
        const query = `host=${encodeURIComponent(host)}`
        const appIdUrl = getAppIdUrl(query)
        const { data } = await axios.get(appIdUrl)
        url = getAppUrl(data.id)
      } catch (error) {
        this.setState({ error: true })
        console.error(`ERROR RESOLVING APP HOST:  ${error}`)
      }
    }

    try {
      const response = await axios.get(url)
      this.setState({ app: response.data, appId: response.data.id }, () => {
        const { app } = this.state
        document.title = app.name
      })
    } catch (error) {
      this.setState({ error: true })
      Sentry.captureException(
        new AppLoadError(`Failed to get app URL: ${url}`, error)
      )
      console.info(`ERROR GETTING APP: ${error}`)
    }
  }

  handleLoadLibraries = () => {
    this.setState({ librariesReady: true })
  }

  handleUnauthorized = () => {
    this.setState({ authenticationError: true })
  }

  handleRouteChange = newRoute => {
    this.setState({ currentComponentId: newRoute.componentId })
  }

  getParams = () => {
    return qs.parse(window.location.search.substring(1))
  }

  getOS = () => {
    return this.getParams().os
  }

  getLayoutGuides = () => {
    const params = this.getParams()

    if (SafeAreaInsets.top > 0) {
      return {
        top: SafeAreaInsets.top,
        bottom: 0,
      }
    }
    const offsetTop = params?.['offset_top'] ?? 0
    const offsetBottom = params?.['offset_bottom'] ?? 0

    return {
      top: +offsetTop,
      bottom: +offsetBottom,
    }
  }

  beforeInstallPrompt = installPrompt => {
    if (ANDROID_INSTAL_PROMPT_ENABLED) {
      this.setState({ installPrompt })
    }
  }

  componentDidMount() {
    this.requestApp()

    window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt)
  }

  renderStatusBar() {
    const { app, currentComponentId } = this.state

    if (isMobile) {
      return null
    }

    if (!app || !app.components) {
      return null
    }

    const component = app.components[currentComponentId]
    const layoutGuides = this.getLayoutGuides()

    if (!component) {
      return null
    }

    const { statusBarStyle } = component
    let light = false
    const hasNotch = layoutGuides.top > 20

    if (statusBarStyle === 'hidden') {
      return null
    }

    if (statusBarStyle === 'light') {
      light = true
    }

    return (
      <div className="status-bar-wrapper">
        <StatusBar platform={this.getOS()} light={light} hasNotch={hasNotch} />
      </div>
    )
  }

  render() {
    const {
      app,
      librariesReady,
      authenticationError,
      installPrompt,
      error,
      appId,
    } = this.state

    let { offsetTop, analytics, previewType } = this.props

    const {
      offsetTop: newOffsetTop,
      previewType: newPreviewType,
    } = this.getParams()

    if (!offsetTop) {
      offsetTop = newOffsetTop
    }

    if (!previewType) {
      previewType = newPreviewType
    }

    const disableAnalytics = !analytics

    if (authenticationError) {
      return (
        <div>
          <h1>Unauthorized</h1>
        </div>
      )
    } else if (error) {
      return (
        <div>
          <h1>Error getting app</h1>
        </div>
      )
    }

    const layoutGuides = this.getLayoutGuides()
    const libraries = getLibraries(app)

    return (
      <Fragment>
        <FeatureFlagIdentitySync app={app} />
        <Helmet>
          <link
            rel="shortcut icon"
            href={`${getAppIconUrl(appId)}?size=180&radius=7`}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${getAppIconUrl(appId)}?size=180&radius=7`}
          />
        </Helmet>
        <LibraryLoader app={app} onLoad={this.handleLoadLibraries} />
        <Runner
          app={librariesReady ? app : null}
          baseURL={DATABASE_URL}
          baseAPIURL={DATABASE_API_URL}
          assetsBaseURL={ASSETS_URL}
          fileUploadsBaseURL={FILE_UPLOADS_URL}
          imageUploadsBaseURL={IMAGE_UPLOADS_URL}
          notificationsURL={NOTIFICATIONS_URL}
          transitionStyle={this.getOS()}
          onRoute={this.handleRouteChange}
          layoutGuides={layoutGuides}
          libraries={librariesReady && libraries}
          offsetTop={offsetTop}
          disableAnalytics={disableAnalytics}
          previewer
          previewType={previewType}
        />
        {app ? this.renderStatusBar() : null}
        {app && installPrompt ? (
          <InstallPrompt app={app} onShow={installPrompt} />
        ) : null}
        <IOSInstallPrompt app={app} />
      </Fragment>
    )
  }
}
