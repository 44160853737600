import QS from 'qs'
import axios from 'axios'

const baseURL = process.env.REACT_APP_COMPONENT_URL
const marketplaceURL =
  process.env.REACT_APP_COMPONENT_REGISTRY_URL ||
  'https://component-registry.herokuapp.com'
const localBaseURL = process.env.REACT_APP_DEV_SERVER_URL
const cache = {}

export const getLibraryDependencies = async (app, localLibraries = []) => {
  const { librariesUsed } = app

  let libraries = []

  if (librariesUsed) {
    let names = librariesUsed
    names = JSON.stringify(names)
    names = encodeURIComponent(names)

    const url = `${marketplaceURL}/api/libraries`
    const { data } = await axios.get(url, {
      params: { names, orgId: app?.OrganizationId, appId: app?.id },
    })

    libraries = data
  }

  // add local libraries
  localLibraries.map(localLibrary => {
    const filtered = libraries.filter(lib => lib?.name === localLibrary) || []

    if (filtered.length > 0) return null

    return (libraries = [...libraries, { name: localLibrary, version: 'dev' }])
  })

  return libraries
}

// Returns promise
export const loadLibrary = (name, version, isLocal, authToken) => {
  let url = `${baseURL}/${name}/${version}/runtime-${version}.js`

  if (isLocal && authToken) {
    url = `${localBaseURL}/libraries/${name}/runtime-dev.js?authToken=${authToken}`
  }

  // Check if already in cache
  if (cache[url]) {
    return cache[url]
  }

  const script = document.createElement('script')
  script.type = 'application/javascript'
  script.src = url
  script.async = true
  script.setAttribute('crossorigin', '')
  document.body.appendChild(script)

  const promise = new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      window.setTimeout(() => {
        resolve()
      }, 100)
    })

    script.addEventListener('error', () => {
      reject(new Error(`Script could not load: ${url}`))
    })
  })

  cache[url] = promise

  return promise
}

export const loadLibraries = async app => {
  const authToken = getAuthToken()
  let localLibraries = []

  if (authToken) {
    try {
      const params = { authToken }
      const url = `${localBaseURL}/libraries/?${QS.stringify(params)}`
      const { data } = await axios.get(url)
      if (Array.isArray(data)) localLibraries = data
    } catch (err) {
      // Do nothing
    }
  }

  const libraryDependencies = await getLibraryDependencies(app, localLibraries)

  const promises = libraryDependencies.map(({ name, version }) => {
    if (localLibraries.includes(name)) {
      return loadLibrary(name, null, true, authToken)
    } else {
      return loadLibrary(name, version)
    }
  })

  return Promise.allSettled(promises)
}

export const getAuthToken = () => {
  const params = QS.parse(window.location.search.substring(1))

  return params.sessionToken
}

export const getLibraries = app => {
  return window.protonRuntime || {}
}
