// Configure regeneratorRuntime first, so we don't error out
import './runtimeConfig'
import './rn-compatibility'
import 'core-js/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import { unregister } from './registerServiceWorker'

import App from './components/App'

async function main() {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    user: {
      key: 'pre-init-fixed-key',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })

  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <LaunchDarklyProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </LaunchDarklyProvider>
  )

  unregister()
}

main()
