import React, { useEffect, useState, useCallback } from 'react'

import { contrastWithBackground } from 'utils/colors'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import Input from 'components/Shared/Input'
import Modal from 'components/Shared/Modal'

const SendSMS = ({
  branding,
  isOpen,
  name,
  handleClose,
  handleChange,
  handleSubmit,
  value,
  sending,
}) => {
  const iconFill = contrastWithBackground(branding.primary)

  const renderPrepend = () => (
    <Icon small type="exposure-plus-1" className="sms-prepend-icon" />
  )
  const renderAppend = () => {
    return (
      <Button
        type="submit"
        backgroundColor={branding.primary}
        className="sms-button-append"
        disabled={!isValid}
        loading={sending}
      >
        <Icon type="send" fill={iconFill} />
      </Button>
    )
  }

  const [isValid, setIsValid] = useState(false)
  const validate = useCallback(() => {
    if (value.length < 12 || value.length > 12) return setIsValid(false)

    return setIsValid(true)
  }, [value])

  useEffect(() => {
    validate()
  }, [validate])

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h1>Send {name} Link</h1>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Input
          label="Recipient's Phone Number (US Numbers Only)"
          id="sendToPhone"
          placeholder="000 000 0000"
          type="text"
          options={{
            phone: true,
            phoneRegionCode: 'us',
          }}
          prepend={renderPrepend()}
          append={renderAppend()}
          autoFocus
          value={value}
          onChange={handleChange}
        />
      </form>
    </Modal>
  )
}

export default SendSMS
