import React from 'react'
import ReactDOM from 'react-dom'

import Button from 'components/Shared/Button'
import './Modal.css'

const Modal = ({ children, isOpen, handleClose, hideClose }) => {
  const renderModal = () => {
    return (
      <>
        <div className="modal">
          <div className="modal-dimmer" onClick={handleClose} />
          <div className="modal-container">
            {!hideClose && (
              <Button
                className="modal-close"
                icon="close"
                onClick={handleClose}
              />
            )}
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </>
    )
  }

  if (!isOpen) return null

  return ReactDOM.createPortal(renderModal(), document.body)
}

export default Modal
